<template>

  <b-card>
    <div class="row">
      <div class="col-12">
        <hwa-input
          v-model="contact.phone"
          label="Phone"
        />
      </div>
      <!-- <div class="col-12">
        <hwa-input
          v-model="contact.phone_text"
          label="Phone Text"
        />
      </div> -->
      <div class="col-12">
        <hwa-input
          v-model="contact.email"
          label="Email"
        />
      </div>
      <!-- <div class="col-12">
        <hwa-input
          v-model="contact.email_text"
          label="Email Text"
        />
      </div> -->
      <div class="col-12">
        <div class="row">
          <div class="col-md-12">
            <hwa-input
              v-model="contact.address"
              label="Address"
            />
          </div>
          <!-- <div class="col-md-12" style="margin-top:-20px">

            <hwa-input
              v-model="contact.address2"
              placeholder=""
            />
          </div> -->
        </div>

        <!-- <hwa-text-area
          v-model="contact.address"
          label="Address"
        /> -->
      </div>
      <div class="col-12 mt-2">
        <hwa-button
          :loading="loading"
          class="float-right"
          label="Update"
          @onClick="updateContact()"
        />
      </div>
    </div>
  </b-card>
</template>

<script>

import HwaInput from '@/layouts/components/HwaInput.vue'
import { BCard } from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  name: 'ContactContent',
  components: {
    HwaButton,
    HwaInput,
    BCard,
    HwaTextArea,
  },
  mixins: [showToast],
  data: () => ({
    contact: {
      id: '',
      phone: '',
      phone_text: '',
      email: '',
      email_text: '',
      address: '',
      address2: '',
    },
    loading: false,
  }),
  computed: {
    contactContent() {
      return this.$store.getters['auth/contactContent']
    },
  },
  watch: {
    contactContent(val) {
      this.contact = val
    },
  },
  created() {
    this.contact = this.contactContent
    this.getContactContent()
  },
  methods: {
    updateContact() {
      this.loading = true
      this.$http.put(`api/contacts/${this.contact.id}`, this.contact)
        .then(res => {
          this.$store.commit('auth/setContactContent', res.data)
          this.showSuccess('Updated Successfully')
          this.loading = false
        }).catch(err => {
          this.loading = false
          this.showError('Failed to update')
        })
    },
    getContactContent() {
      this.$http.get('api/contacts')
        .then(res => {
          this.$store.commit('auth/setContactContent', res.data[0])
          this.loaded = true
        })
    },
  },
}
</script>
